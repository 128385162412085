import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { FaFilePdf } from "react-icons/fa";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function ViewRFQ() {
    const location = useLocation();
    const receivedData = location.state;
  console.log(receivedData);
  
    

    const generatePdf = () => {
        const input = document.getElementById("pdf-content");
    
        html2canvas(input).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4"); // Portrait, millimeters, A4 size
          const imgWidth = pdf.internal.pageSize.getWidth();
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
          pdf.save(`${receivedData?.indentno}.pdf`);
        });
      };

      
  const formatDate = (inputDateStr) => {
    // Step 1: Parse the date string into a Date object
    const inputDate = new Date(inputDateStr);

    // Step 2: Format the date into dd/mm/yyyy format
    const day = inputDate.getDate()?.toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1)?.toString().padStart(2, "0"); // Months are zero-indexed
    const year = inputDate.getFullYear();

    // Return the formatted date string
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
            <div className="container mt-3">
        <button type="button" class="btn btn-success" onClick={generatePdf}>
          <FaFilePdf />
          &nbsp;PDF
        </button>
      </div>

      <section className="mt-3" id="pdf-content">
       
       <div className="container border p-5">
         <div className="row " style={{ backgroundColor: "#D6EFD8" }}>
           <div className="col-md-4 text-start">
           <img src="/images/logo-removebg.png" alt="" srcset="" style={{width:'200px',height:'180px'}} />
           </div>

           <div className="col-md-8 text-end align-items-center pt-5">
             <h2>Request For Quotation</h2>
           </div>
         </div>
         <div class="row d-flex justify-content-end text-start ">
            <div className="col-md-12">
       <h6>From :</h6>
       Engeenring Solution
       <br />
       Karnataka
       <br />
       India
       <br />
       +91-1234556893
            </div>
            </div>
            <div class="row d-flex justify-content-end text-start mt-3 pb-3" style={{borderTop:'1px solid black',borderBottom:'1px solid black'}}>
            <div className="col-md-12">
       <h6>To :</h6>
       {receivedData?.vendordetails[0]?.vendorfirstname}&nbsp;    {receivedData?.vendordetails[0]?.vendorlastname}
       <br />
       {receivedData?.vendordetails[0]?.billingaddressaddress}
       <br />
       {receivedData?.vendordetails[0]?.billingaddresscity}
       <br />
       {receivedData?.vendordetails[0]?.billingaddressstate}
       <br />
       {receivedData?.vendordetails[0]?.billingaddresscountry}, {receivedData?.vendordetails[0]?.billingaddresspincode}
       <br />
       {receivedData?.vendordetails[0]?.billingaddressphone}
            </div>
            </div>
    
         <div class="row  d-flex justify-content-start">
         <div className="col-md-3 text-start">
             <p>
               <b>RFQ No. :</b>
             </p>
           </div>
           <div className="col-md-3 text-start">
             {receivedData?.rfqno}
           </div>
           <div className="col-md-3 text-end">
             <p>
               <b>RFQ Date :</b>
             </p>
           </div>
           <div className="col-md-3 text-end">
             {formatDate(receivedData?.rfqdate)}
           </div>
         </div>

         <div class="row  d-flex justify-content-start">
         <div className="col-md-3 text-start">
             <p>
               <b>Quotation Deadline :</b>
             </p>
           </div>
           <div className="col-md-3 text-start">
             {formatDate(receivedData?.quotedeadline)}
           </div>
           <div className="col-md-3 text-end">
             <p>
               <b>Indent No. :</b>
             </p>
           </div>
           <div className="col-md-3 text-end">
             {receivedData?.indentdetails?.indentno}
           </div>
         </div>
      
         <div className="row mt-2">
           <table class="table table-bordered">
             <thead style={{ backgroundColor: "#F6F5F2" }}>
               <tr>
                 <th scope="col">Sl.no</th>
                 <th scope="col">Item Name</th>
                 <th scope="col">Unit</th>
                 <th scope="col">Stock Qty.</th>
                 <th scope="col">Quantity Required</th>
                 <th scope="col">Remarks</th>
               </tr>
             </thead>
             <tbody>
               {receivedData?.indentdetails?.indentItems?.map((item, index) => {
                 return (
                   <tr>
                     <td>{index + 1}</td>
                     <td>{item.itemname}</td>
                     <td>{item.unit}</td>
                     <td>{item.stockquantity}</td>
                     <td>{item.quantityrequired}</td>
                     <td>{item.remarks}</td>
                   </tr>
                 );
               })}
             </tbody>
           </table>
         </div>

         <div className="row mb-4">
           <div className="col-md-6 offset-6 " style={{ marginTop: "90px" }}>
             <small>Authorized Signature</small>
           </div>
         </div>
       </div>
     </section>
    </div>
  )
}

export default ViewRFQ